import type { BannerProps } from '@imprivata-cloud/components';
import type { ReactNode } from 'react';

export enum ErrorType {
  GLOBAL = 'global',
  FULL_PAGE = 'full-page',
}

export type ErrorConfig = {
  errorCode: string;
  message?: string;
  errorType: ErrorType;
  type: BannerProps['type'];
  errorView?: ReactNode;
};

export type ErrorConfigMap = {
  [errorCode: string]: ErrorConfig;
};
