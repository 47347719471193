// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Col, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginComponent } from '../../../components/login/LoginComponent';
import Separator from '../../../components/separator/Separator';
import useTenantLogin from '../../../setup/hooks/useTenantLogin';
import './PortalLogin.less';
import { SPANS_PORTAL, tracer } from '../../../utils/tracer';

export type PortalLoginProps = {
  workflowId?: string;
};

const PortalLogin = ({ workflowId }: PortalLoginProps) => {
  const { t } = useTranslation();
  const { mutate: login } = useTenantLogin();

  const onLogin = (email: string) => {
    tracer.startSpan(SPANS_PORTAL.lookup_tenant);
    login({ email, workflowId });
  };

  return (
    <div className="portal-window elevation-xl p-xxxl">
      <Row className="portal-container">
        <Col xs={0} sm={0} md={12} lg={14} className="portal-info">
          <div className="portal-info-steps">
            <h1>{t('portal.info.title')}</h1>
            <Separator />
            <p className={'portal-info-text'}>{t('portal.info.steps')}</p>
          </div>
          <PortalFooter />
        </Col>

        <Col xs={24} sm={24} md={12} lg={10}>
          <LoginComponent onSubmit={onLogin} />
        </Col>
      </Row>
    </div>
  );
};

export { PortalLogin };

const PortalFooter = () => {
  return (
    <div className={'portal-info-footer'}>
      <p>© 2025 Imprivata, Inc. All rights reserved.</p>
    </div>
  );
};
