import { Button, type ButtonProps } from '@imprivata-cloud/components';
import { Form } from 'antd';
import type { FormInstance } from 'antd/lib';
import type { NamePath } from 'antd/es/form/interface';
import './ContinueButton.less';

export const checkFormErrors = <FormItems,>(form: FormInstance<FormItems>) =>
  (form.getFieldsError() || []).some((field) => !!field.errors.length);

const isDisabled = <FormItems,>(
  form: FormInstance<FormItems>,
  errorCheck?: (form: FormInstance<FormItems>) => boolean,
  validateDirty?: boolean | Array<NamePath<FormItems>>,
) => {
  const hasErrors = errorCheck ? errorCheck(form) : checkFormErrors(form);
  const isDirty = validateDirty
    ? Array.isArray(validateDirty)
      ? form.isFieldsTouched(validateDirty, true)
      : form.isFieldsTouched(true)
    : true;

  return hasErrors || !isDirty;
};

export interface ContinueButtonProps<FormItems> extends ButtonProps {
  secondaryButton?: ButtonProps;
  validateDirty?: boolean | string[];
  overrideErrorCheck?: (form: FormInstance<FormItems>) => boolean;
}

export const ContinueButton = <FormItems,>({
  secondaryButton,
  overrideErrorCheck,
  validateDirty,
  ...props
}: ContinueButtonProps<FormItems>) => {
  return (
    <Form.Item shouldUpdate>
      {(form) => (
        <div className="continue-button">
          <Button
            size="large"
            type="primary"
            disabled={isDisabled(form, overrideErrorCheck, validateDirty)}
            block
            {...props}
            className={`continue-button ${props.className || ''}`}
          />
          {secondaryButton && <Button {...secondaryButton} type="text" />}
        </div>
      )}
    </Form.Item>
  );
};

export default ContinueButton;
