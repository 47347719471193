import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { STEPS, TRANSLATION_BUTTON } from '../../constants';
import useCompleteSetupMutation from '../../hooks/useCompleteSetupQuery';

const TRANSLATION_ROOT = `setup.${STEPS.UPDATE_CONDITIONAL_POLICIES}.content`;

const UpdateConditionalPolicies = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { mutate } = useCompleteSetupMutation();

  const error = searchParams.get('error');
  if (error) {
    return <h1>{error}</h1>;
  }

  const handleNextStep = () => {
    mutate(true);
  };

  return (
    <>
      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description`} />
        &nbsp;
        <a href="https://example.com" target="_blank" rel="noopener noreferrer">
          {t('common.see-instructions')}
        </a>
      </p>

      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.conditional-access`} />
      </p>

      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.exclude-imprivata-entra-id`} />
      </p>

      <ContinueButton label={t(TRANSLATION_BUTTON)} onClick={handleNextStep} />
    </>
  );
};

export default UpdateConditionalPolicies;
