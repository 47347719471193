export enum STEPS {
  START = 'start',
  IDP_CHOICE = 'idp-choice',
  ORG_INFO = 'org-info',
  DATA_PROCESSING_ADDENDUM = 'data-processing-addendum',
  INTEGRATION_TOKEN = 'integration-token',
  EXTERNAL_IDP_INFO = 'external-idp-info',
  UPDATE_CONDITIONAL_POLICIES = 'update-conditional-policies',
  TRUST_IMPRIVATA_SERVERS = 'trust-imprivata-servers',
  CONNECT_ENTRA_ID = 'connect-entraid',
  ERROR = 'error',
  SETUP_COMPLETE = 'setup-complete',
}

export const TRANSLATION_BUTTON = 'common.continue-button';
