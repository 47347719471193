import { Endpoints } from './constants';
import type { TenantLookupResponse } from './types';
import { fetcher } from './utils';

const lookupTenant = async (userEmail: string, workflowId?: string): Promise<TenantLookupResponse> => {
  return fetcher(Endpoints.TENANT_LOOKUP_BY_DOMAIN, {
    method: 'POST',
    body: JSON.stringify({ userEmail }),
    workflowId,
  });
};

const getClientUserSession = async (workflowId: string) => {
  return fetcher(Endpoints.GET_CLIENT_USER_SESSION, {
    method: 'POST',
    workflowId,
  });
};

const getLandingPage = async (workflowId: string, tenantId: string) => {
  return fetcher(
    Endpoints.GET_LANDING_PAGE,
    {
      method: 'POST',
      workflowId,
    },
    tenantId,
  );
};

const userLogout = async (tenantId: string) => {
  return fetcher(
    Endpoints.LOGOUT,
    {
      method: 'GET',
    },
    tenantId,
  );
};

export { lookupTenant, getClientUserSession, getLandingPage, userLogout };
