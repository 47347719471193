import ErrorImg from '../assets/full_page_error.svg?react';
import './ErrorView.less';

const ErrorView = () => {
  return (
    <div className={'error-view-container'}>
      <ErrorImg />
      <h3 className={'error-view-container-text'}>Unable to continue</h3>
      <span>Use the link from the Welcome email to start again.</span>
    </div>
  );
};

export { ErrorView };
