import { useNavigate, useSearchParams } from 'react-router-dom';
import { STEPS } from '../constants';
import { useCallback } from 'react';

export function useNavigateToNextStep() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return useCallback((step: STEPS) => {
    navigate({
      pathname: step === STEPS.START ? '/setup' : `/setup/${step}`,
      search: `?${searchParams.toString()}`
    })
  }, [navigate, searchParams]);
}
