import { Button } from '@imprivata-cloud/components';
import ProductCardPattern from '../../../assets/product_card_pattern.svg?react';
import './UnpurchasedProductCard.less';

type UnpurchasedProductCardProps = {
  productName: string;
  productLink: string;
};

export const UnpurchasedProductCard = ({ productName, productLink }: UnpurchasedProductCardProps) => {
  return (
    <div className={'non-purchased-card'}>
      <div className={'title-container'}>
        <ProductCardPattern style={{ position: 'absolute', top: 0, right: 0 }} />
        <div className={'title'}>{productName}</div>
      </div>
      <div className={'link-container'}>
        <Button
          className={'link'}
          label={'Learn more'}
          size={'small'}
          type={'link'}
          href={productLink}
          target={'_blank'}
          rel="noreferrer"
        />
      </div>
    </div>
  );
};
