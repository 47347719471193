// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import IdPConnectImg from '../assets/IdP_connect.svg?react';
import IntroImg from '../assets/Intro_1.svg?react';
import AboutOrgImg from '../assets/about_org.svg?react';
import ConnectEntraIDImg from '../assets/connect_EntraID.svg?react';
import EntraTrustImg from '../assets/entraID_trust.svg?react';
import EntraPoliciesImg from '../assets/entra_policies.svg?react';
import IDPChoiceImg from '../assets/idp_choice.svg?react';
import SetupCompleteImg from '../assets/setup_complete.svg?react';
import ErrorBoundary from '../errorHandler/ErrorBoundary';
import { ErrorView } from '../errorHandler/ErrorView';
import { Notifications } from '../errorHandler/context/Notifications';
import { getPathKey } from '../utils/utils';
import { STEPS } from './constants';
import { SetupPageLayout } from './layout/SetupPageLayout';

// Graphics for each setup step
const graphics: Record<string, React.ReactNode> = {
  setup: <IntroImg />,
  [`setup.${STEPS.INTEGRATION_TOKEN}`]: <IdPConnectImg />,
  [`setup.${STEPS.IDP_CHOICE}`]: <IDPChoiceImg />,
  [`setup.${STEPS.ORG_INFO}`]: <AboutOrgImg />,
  [`setup.${STEPS.EXTERNAL_IDP_INFO}`]: <IdPConnectImg />,
  [`setup.${STEPS.UPDATE_CONDITIONAL_POLICIES}`]: <EntraPoliciesImg />,
  [`setup.${STEPS.TRUST_IMPRIVATA_SERVERS}`]: <EntraTrustImg />,
  [`setup.${STEPS.CONNECT_ENTRA_ID}`]: <ConnectEntraIDImg />,
  [`setup.${STEPS.SETUP_COMPLETE}`]: <SetupCompleteImg />,
};

// Main setup wrapper, dynamically loads side image and title translation
const Setup = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathKey = getPathKey(location.pathname);
  const titleTranslation = t(`${pathKey}.page-title`, 'notFound');
  const title = titleTranslation === 'notFound' ? '' : titleTranslation;

  return (
    <ErrorBoundary fallback={<ErrorView />}>
      <Notifications translationRoot={'setup'} key={pathKey}>
        <SetupPageLayout title={title} graphic={graphics[pathKey]}>
          <Outlet />
        </SetupPageLayout>
      </Notifications>
    </ErrorBoundary>
  );
};

export default Setup;
