import { type EventsType, useIdleTimer } from 'react-idle-timer';

export const useIdleTimeout = (timeoutInMins: number, handleIdle: () => void) => {
  const idleTimer = useIdleTimer({
    timeout: timeoutInMins * 60 * 1000,
    events: USER_ACTIVITIES_EVENTS,
    onIdle: () => {
      handleIdle();
    },
    name: 'idle-ui-timeout',
    stopOnIdle: true,
    startManually: true,
  });

  const startIdleTimer = () => idleTimer.activate();
  return { startIdleTimer };
};

export const USER_ACTIVITIES_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
  'touchstart',
];
