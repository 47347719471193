import { InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAadConsentUrl } from '../../../api/setupServices';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { tracer, SPANS_SETUP } from '../../../utils/tracer';

const TRANSLATION_ROOT = 'setup.connect-entraid.content';

type FormValues = {
  'Entra Id': string;
};

const ConnectEntraId = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm<FormValues>();

  const onSubmit = async (values: FormValues) => {
    try {
      tracer.startSpan(SPANS_SETUP.get_aad_consent_url);
      const { aadConsentUrl } = await getAadConsentUrl({ aadTenantId: values['Entra Id'] });
      tracer.endSpan(SPANS_SETUP.get_aad_consent_url);
      // going off Impr domain, might want to persist state
      window.location.href = aadConsentUrl;
    } catch (e) {
      tracer.endSpan(SPANS_SETUP.get_aad_consent_url, {error: e});
      console.error('[TODO][ErrorBanner]', e);
      alert('Wrong Tenant Id');
    }
  };

  return (
    <>
      <p>{t(`${TRANSLATION_ROOT}.entraid-find-instructions`)}</p>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="Entra Id" rules={[{ required: true }]}>
          <InputBox label={t(`${TRANSLATION_ROOT}.entraid-label`)} required autoFocus />
        </Form.Item>
        <ContinueButton validateDirty htmlType="submit" label={t(`${TRANSLATION_ROOT}.next-button`)} />
      </Form>
      <p>{t(`${TRANSLATION_ROOT}.privilege-instructions`)}</p>
    </>
  );
};

export default ConnectEntraId;
