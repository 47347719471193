import { Dropdown, type MenuProps, Modal, Space } from 'antd';
import SettingsIcon from '../../../assets/settings.svg?react';
import './SettingsMenu.less';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from '@imprivata-cloud/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntegrationToken from '../icc-token/IntegrationToken';

const SettingsMenu = () => {
  const { t } = useTranslation();
  const [iccTokenPage, setIccTokenPage] = useState(false);

  const redirectToICCPage = () => {
    console.log('Open ICC Token Page');
    setIccTokenPage(true);
  };

  const items: MenuProps['items'] = [
    {
      label: t('portal.settings.icc-token'),
      key: 'icc-token-page',
      onClick: redirectToICCPage,
    },
  ];

  return (
    <>
      <Space direction="horizontal" style={{ paddingTop: '1rem' }}>
        <Space wrap>
          <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }} trigger={['click']}>
            <Button icon={<SettingsIcon />} className={'settings-button'} />
          </Dropdown>
        </Space>
      </Space>
      <Modal
        open={iccTokenPage}
        onCancel={() => setIccTokenPage(false)}
        onOk={() => setIccTokenPage(false)}
        footer={null}
        closeIcon={<CloseOutlined />}
        wrapClassName={'icc-token-modal'}
        destroyOnClose
      >
        <IntegrationToken />
      </Modal>
    </>
  );
};

export default SettingsMenu;
