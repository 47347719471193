/** @see: src/errors/app.ts */

export enum ErrorMessage {
  // FatalErrors - Fullpage
  SYSTEM_ERROR = 'SYSTEM_ERROR',
}

export enum ErrorCode {
  UNKNOWN = 'unknown-error',
  LINK_DEAD = 'link-dead',
  FATAL_ERROR = 'FATAL_ERROR',
  FATAL = 'fatal',
  NOT_FOUND = 'not-found',
  PERMISSION_DENIED = 'forbidden',
  IMPLEMENTATION_DEFECT = 'implementation-defect',
  INTERNAL = 'internal',
  INTERNAL_1 = 'INTERNAL',
  INVALID_TENANT_ID = 'invalid-tenant-id',
  INVALID_TENANT_STATE = 'invalid-tenant-state',
  INCOMPLETE_OPERATION = 'incomplete-operation',
  SESSION_EXPIRED = 'session-expired',
  INVALID_FILE_TYPE = 'invalid-file-type',
  LOGO_UPLOAD_ERROR = 'logo-upload-error',
}

export enum SESSION_ERROR_CODES {
  SESSION_EXPIRED = 'session-expired',
  INVALID_SESSION_ID = 'invalid-session-id',
  SESSION_NOT_FOUND = 'session-not-found',
}
