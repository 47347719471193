import Icon from '@ant-design/icons';
import { Button, InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { StorageKeys } from '../../api/constants';
import CopyIcon from '../../assets/icons/copy.svg?react';
import { copyToClipboard } from '../../utils/utils';
import ContinueButton from '../continueButton/ContinueButton';

export type ICCTokenFormValues = {
  integrationId: string;
};

type ICCTokenFormProps = {
  onSubmit: (values: ICCTokenFormValues) => void;
  translationRoot: string;
  installationTokenValue: string;
};

const ICCTokenForm = ({ onSubmit, translationRoot, installationTokenValue }: ICCTokenFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ICCTokenFormValues>();

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item name={'integrationId'} rules={[{ required: true }]}>
        <InputBox label={t(`${translationRoot}.input`)} required autoFocus />
      </Form.Item>
      <ContinueButton
        validateDirty
        htmlType="submit"
        label={t(`${translationRoot}.button`)}
        data-testid="create-token-button"
      />
      <Button
        size="large"
        style={{
          visibility: installationTokenValue ? 'visible' : 'hidden',
          border: 'none',
          boxShadow: 'none',
        }}
        icon={<Icon component={CopyIcon} />}
        label={t(`${translationRoot}.copy`)}
        onClick={() => {
          copyToClipboard(installationTokenValue);
          sessionStorage.setItem(StorageKeys.INTEGRATION_TOKEN_USED, 'true');
        }}
        block
        data-testid="copy-token-button"
      />
      <p>
        {t(`${translationRoot}.text-2`)}
        <a href="https://www.imprivata.com" target="_blank" rel="noopener noreferrer">
          {t('common.see-instructions')}.
        </a>
      </p>
    </Form>
  );
};

export { ICCTokenForm };
