import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StorageKeys, SetupState } from '../../../api/constants';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { getPortalUrl } from '../../../utils/utils';
import { STEPS } from '../../constants';
import { useNavigateToNextStep } from '../../hooks/useNavigateToNextStep';
import { tracer, SPANS_SETUP } from '../../../utils/tracer';
import * as api from '../../../api/setupServices';
import { FatalError, type ApiError } from '../../../errorHandler/errors';
import { useNotifications } from '../../../errorHandler/context/Notifications';

const TRANSLATION_ROOT = `setup.${STEPS.SETUP_COMPLETE}.content`;
const TRANSLATION_BUTTON = 'common.continue-imprivata-access';

const SetupComplete = () => {
  const { t } = useTranslation();
  const { emitError } = useNotifications();
  const navigateToNextStep = useNavigateToNextStep();
  const isCompleteAllowed = sessionStorage.getItem(StorageKeys.INTEGRATION_TOKEN_USED)
    || sessionStorage.getItem(StorageKeys.EXT_IdP_USED);

  useEffect(() => {
    const setupState = sessionStorage.getItem(StorageKeys.TENANT_SETUP_STATE);
    console.log('[Setup Complete] Setup State: ', setupState);
    async function completeSetup(){
      tracer.startSpan(SPANS_SETUP.complete_setup);
      try {
        await api.sendTenantSetupCompleteRequest(false)
        sessionStorage.setItem(StorageKeys.TENANT_SETUP_STATE, SetupState.COMPLETED);
        tracer.endSpan(SPANS_SETUP.complete_setup);
      } catch (e) {
        tracer.endSpan(SPANS_SETUP.complete_setup, { error: e });
        // If the complete setup call fails, show a full page error.
        emitError(new FatalError(e as ApiError));
      }
    }
    if (setupState === SetupState.STARTED && isCompleteAllowed) {
      void completeSetup();
    }
  }, [emitError, isCompleteAllowed]);

  const handleGoToImprivataAccess = () => {
    if (!isCompleteAllowed) {
      navigateToNextStep(STEPS.START);
      return;
    }
    localStorage.clear();
    sessionStorage.clear();
    const portalUrl = getPortalUrl();
    window.location.href = `${portalUrl}`;
  };

  return (
    <>
      <h1 className="title">
        {t(isCompleteAllowed ? `${TRANSLATION_ROOT}.title` : `${TRANSLATION_ROOT}.cannot-complete-title`)}
      </h1>
      <p style={{ marginBottom: '8.13rem' }}>
        <Trans
          i18nKey={isCompleteAllowed ? `${TRANSLATION_ROOT}.description` : `${TRANSLATION_ROOT}.cannot-complete-desc`}/>
      </p>
      <ContinueButton label={t(isCompleteAllowed ? TRANSLATION_BUTTON : `${TRANSLATION_ROOT}.cannot-complete-button`)}
                      onClick={handleGoToImprivataAccess}/>
    </>
  );
};

export default SetupComplete;
