import React from 'react';
import { IntegrationTokenPage } from '../../../common/integration-token/IntegrationTokenPage';
import '../../../setup/layout/SetupPageLayout.less';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import IdPConnectImg from '../../../assets/IdP_connect.svg?react';

const IntegrationToken = () => {
  const { t } = useTranslation();
  return (
    <div className="landing-page" data-testid="landing-page">
      <div className="setup-window p-xxxl elevation-xl">
        <div className="setup-container">
          <Row>
            <Col sm={24} md={24} lg={14} className="setup-content p-xxxl">
              <h1 style={{ color: 'black' }}>{t('common.integration-token.page-title')}</h1>
              <IntegrationTokenPage isSetup={false} />
            </Col>
            <Col sm={0} md={0} lg={10}>
              <div className="setup-graphic">
                <div>
                  <IdPConnectImg />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default IntegrationToken;
