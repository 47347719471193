import ErrorImg from '../../../assets/icons/dead-link-setup.svg?react';
import './LinkDeadErrorView.less';
import { _getEnv } from '../../../api/utils';

const LinkDeadErrorView = () => {
  const { currentEnv, tld } = _getEnv();
  const portalLink = `https://access.app.${currentEnv}.imprivata.${tld}/`;
  return (
    <div className={'link-dead-container'}>
      <ErrorImg />
      <h3 className={'link-dead-text'}>Initial configuration has already been completed</h3>
      <span>
        Go to <a href={portalLink}>access.imprivata.com</a> to log in
      </span>
    </div>
  );
};

export { LinkDeadErrorView };
