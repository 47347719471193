import { Layout } from 'antd';
import type React from 'react';
import './PageLayout.less';

export type PageFooterProps = {
  footer?: React.ReactNode;
};

const PageFooter = ({ footer }: PageFooterProps) => {
  const { Footer } = Layout;
  return (
    <>
      {footer ? (
        <Footer>{footer}</Footer>
      ) : (
        <Footer className={'footer'}>
          <div className={'footer-content'}>
            <div>Imprivata Enterprise Access Management 2024.13</div>
            <div>©{new Date().getFullYear()} Imprivata, Inc. All rights reserved. </div>
            <div>
              <a href={'https://www.imprivata.com/data-processing-addendum'} target={'_blank'} rel="noreferrer">
                Data Processing Addendum
              </a>{' '}
            </div>
          </div>
        </Footer>
      )}
    </>
  );
};

export default PageFooter;
