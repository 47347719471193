import React, { type PropsWithChildren } from 'react';
import ErrorBoundary from '../../../errorHandler/ErrorBoundary';
import { Notifications } from '../../../errorHandler/context/Notifications';
import { getPathKey } from '../../../utils/utils';
import { PortalErrorView } from '../errors/PortalErrorView';

export type PortalLayoutProps = {
  displayHeader?: boolean;
  title: string;
};

const PortalLayout = ({ children }: PropsWithChildren<PortalLayoutProps>) => {
  const pathKey = getPathKey(location.pathname);

  return (
    <ErrorBoundary fallback={<PortalErrorView />}>
      <Notifications translationRoot={'portal'} key={pathKey}>
        {children}
      </Notifications>
    </ErrorBoundary>
  );
};

export default PortalLayout;
