import { Banner, type BannerProps } from '@imprivata-cloud/components';
import {
  Fragment,
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { errorConfiguration } from '../ErrorConfig';
import { ErrorView } from '../ErrorView';
import { type AppError, FatalError } from '../errors';
import { ErrorType } from '../types';

export interface NotificationState {
  error?: AppError;
}

export const NotificationContext = createContext<
  | (NotificationState & {
      emitNotification: (bannerProps: BannerProps, clear?: boolean) => void;
      emitError: (error: AppError) => void;
      clearErrorNotifications: () => void;
    })
  | null
>(null);

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

export type NotificationsProps = {
  translationRoot?: string;
};

// Context provider for banners and errors
export const Notifications = ({ translationRoot, children }: PropsWithChildren<NotificationsProps>) => {
  const { t } = useTranslation();
  const [errorView, setErrorView] = useState<ReactNode>();
  const [banner, _setBanner] = useState<BannerProps>();

  // Use this to display success or info banners
  const emitNotification = useCallback((message: BannerProps) => {
    const newBanner = {
      ...message,
      id: new Date().getTime().toString(),
    };

    _setBanner(newBanner);
  }, []);

  const clearErrorNotifications = useCallback(() => {
    _setBanner(undefined);
  }, []);

  // Use this to handle errors
  const emitError = useCallback(
    (err: AppError | string) => {
      let errorCode: string;
      if (typeof err !== 'string') {
        // Don't show banners on fatal errors
        if (err instanceof FatalError) {
          setErrorView(<ErrorView />);
          return;
        }
        // Pull out error code
        errorCode = err?.code || err?.details?.code;
      } else {
        errorCode = err;
      }
      console.debug('[Notification Context: ] error code', errorCode);
      const errorConfig =
        errorConfiguration && errorCode in errorConfiguration ? errorConfiguration[errorCode] : undefined;
      if (errorConfig) {
        switch (errorConfig.errorType) {
          case ErrorType.FULL_PAGE:
            setErrorView(errorConfig.errorView ? errorConfig.errorView : <ErrorView />);
            break;
          case ErrorType.GLOBAL:
            {
              // Create i18n key for error message lookup
              const errorKey = translationRoot
                ? `${translationRoot}.errors.${errorConfig.errorCode}`
                : `errors.${errorConfig.errorCode}`;

              // Initialize banner message
              //
              // NOTE: This is the default banner. If a case does not match, a banner
              // will still be shown! To prevent this, add codes or messages to the `errorsWithoutBanner`
              // array above.
              const bannerMsg: BannerProps = {
                key: errorKey,
                type: errorConfig.type,
                message: t(errorKey as never),
              };

              // Fallback in case translation fails
              if (bannerMsg.message === errorKey) {
                console.debug('[BANNER] Fallback on translation', { bannerMsg });
                bannerMsg.message = translationRoot
                  ? t(`${translationRoot}.errors.something-unexpected`)
                  : t('errors.something-unexpected');
              }

              emitNotification(bannerMsg);
            }
            break;
          default:
            console.error('Unhandled error type', { errorConfig });
        }
      } else {
        console.debug('[Notification Context: ] error config not found for error code: ', errorCode);
        emitNotification({
          key: 'unknown-error',
          type: 'error',
          message: translationRoot
            ? t(`${translationRoot}.errors.something-unexpected`)
            : t('errors.something-unexpected'),
        });
      }
    },
    [t, translationRoot, emitNotification],
  );

  const value = useMemo(
    () => ({
      emitNotification,
      emitError,
      clearErrorNotifications,
    }),
    [clearErrorNotifications, emitNotification, emitError],
  );

  if (errorView) {
    return <Fragment key={'error-view'}>{errorView}</Fragment>;
  }

  return (
    <NotificationContext.Provider value={value}>
      {banner && (
        <Banner
          key={banner.key}
          onClose={clearErrorNotifications}
          message={banner.message}
          type={banner.type}
          duration={10}
        />
      )}
      <Fragment key={'initial-notif-render'}>{children}</Fragment>
    </NotificationContext.Provider>
  );
};
