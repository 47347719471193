// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { InputBox, Button } from '@imprivata-cloud/components';
import { Form, type GetProp, Upload } from 'antd';
import type { UploadProps } from 'antd/lib';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadImg from '../../../assets/upload.svg?react';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import './OrganizationRoute.less';
import type { RcFile } from 'antd/lib/upload';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { setToLocalStorage } from '../../../utils/utils';
import { TRANSLATION_BUTTON, STEPS } from '../../constants';
import useOrganizationInfoSave from '../../hooks/useOrganizationInfoSave';
import { StorageKeys } from '../../../api/constants';
import { useNavigateToNextStep } from '../../hooks/useNavigateToNextStep';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type FileDetailsType = {
  fileContents: File;
  url: string;
};

const ORGANIZATION_NAME_FIELD_NAME = 'organization name';
const EMAIL_FIELD_NAME = 'organization email';
const IMAGE_DETAILS_FIELD_NAME = 'image_details';

interface FormValues {
  [ORGANIZATION_NAME_FIELD_NAME]: string;
  [EMAIL_FIELD_NAME]: string;
  [IMAGE_DETAILS_FIELD_NAME]: FileDetailsType;
}

const TRANSLATION_ROOT = 'setup.org-info.content';

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const OrganizationRoute = () => {
  const [shouldContinue, setShouldContinue] = useState<boolean>(false);
  const [data, setData] = useState<FormValues>();
  const [form] = Form.useForm<FormValues>();
  const organizationInfoSave = useOrganizationInfoSave();
  const { emitError } = useNotifications();

  const onDataFinished = (values: FormValues) => {
    setShouldContinue(true);
    setData(values);
  };

  const onVerify = useCallback(async () => {
    try {
      if (data) {
        console.log(data[EMAIL_FIELD_NAME]);
        setToLocalStorage(StorageKeys.ORG_EMAIL, data[EMAIL_FIELD_NAME]);

        await organizationInfoSave.mutateAsync({
          orgName: data[ORGANIZATION_NAME_FIELD_NAME],
          logoName: data[IMAGE_DETAILS_FIELD_NAME].fileContents.name,
          fileContents: data[IMAGE_DETAILS_FIELD_NAME].fileContents,
        });
      }
    } catch (error) {
      if (error instanceof AppError) {
        emitError(error);
      }
    }
  }, [data, organizationInfoSave, emitError]);

  const onBack = () => {
    setShouldContinue(false);
    if (data) form.setFieldsValue(data);
  };

  return shouldContinue ? (
    <OrganizationVerify data={data} goBack={onBack} onVerify={onVerify} />
  ) : (
    <OrganizationInfo onFinished={onDataFinished} data={data} />
  );
};

const OrganizationInfo = ({ onFinished, data }: { onFinished: (values: FormValues) => void; data?: FormValues }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const { emitError, clearErrorNotifications } = useNotifications();
  const navigateToNextStep = useNavigateToNextStep();
  const [formIsEmpty, setFormIsEmpty] = useState(true);

  const closeBannerIfExists = () => {
    clearErrorNotifications();
  };

  const invalidateImage = () => {
    setImageUrl(undefined);
    form.setFieldValue(IMAGE_DETAILS_FIELD_NAME, undefined);
    form.validateFields([IMAGE_DETAILS_FIELD_NAME]);
  };

  const handleChange: UploadProps['onChange'] = async (info) => {
    if (info.file.status === 'done') {
      console.log('File uploaded successfully:', info.file);
      getBase64(info.file.originFileObj as FileType, (url) => {
        setImageUrl(url);
      });
      const fileDetails = {
        url: URL.createObjectURL(info.file.originFileObj as FileType),
        fileContents: info.file.originFileObj as RcFile,
      };
      form.setFieldsValue({
        [IMAGE_DETAILS_FIELD_NAME]: fileDetails,
      });
    } else {
      invalidateImage();
    }
  };

  const beforeUpload = (file: FileType) => {
    return new Promise<FileType>((resolve, reject) => {
      const isFileTypeOk = file.type.startsWith('image');
      if (!isFileTypeOk) {
        emitError(new AppError({ code: ErrorCode.INVALID_FILE_TYPE }));
        invalidateImage();
        reject(false);
        return;
      }

      const isFileSizeOk = file.size / 1024 <= 100;
      if (!isFileSizeOk) {
        emitError(new AppError({ code: ErrorCode.LOGO_UPLOAD_ERROR }));
        invalidateImage();
        reject(false);
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const image = new Image();
        image.src = e?.target?.result as string;
        image.onload = () => {
          const height = image.height;
          const width = image.width;
          if (height > 100 || width > 200) {
            emitError(new AppError({ code: ErrorCode.LOGO_UPLOAD_ERROR }));
            invalidateImage();
            reject(false);
          } else {
            resolve(file);
          }
        };
      };
      fileReader.readAsDataURL(file);
    });
  };

  const logoPreview = (imageUrl: string | undefined) =>
    imageUrl ? (
      <img src={imageUrl} alt="organization logo" className="upload-preview" />
    ) : (
      <div className="upload-preview">
        <UploadImg />
        &nbsp;
        <span>{t(`${TRANSLATION_ROOT}.logo-upload-sub-label`)}</span>
      </div>
    );

  // we need this to re-populate form with data when user goes back
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setImageUrl(data[IMAGE_DETAILS_FIELD_NAME].url);
    }
  }, [data, form]);

  return (
    <Form form={form} layout="vertical"
          onFinish={onFinished}
          onValuesChange={
            (_, values) => setFormIsEmpty(!values[ORGANIZATION_NAME_FIELD_NAME] && !values[EMAIL_FIELD_NAME])
          }>
      <div className={'subtitle'} />

      {/* Organization Name */}
      <Form.Item name={ORGANIZATION_NAME_FIELD_NAME} rules={[{ required: true }]}>
        <InputBox label={t(`${TRANSLATION_ROOT}.name-label`)} required autoFocus />
      </Form.Item>

      {/* Upload Image */}
      <Form.Item name={IMAGE_DETAILS_FIELD_NAME} rules={[{ required: true, message: '' }]}>
        <Upload
          accept="image/*"
          customRequest={({ onSuccess }) => onSuccess?.('ok')}
          maxCount={1}
          showUploadList={false}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          className="upload-container"
        >
          <label className="logo-upload-label">{t(`${TRANSLATION_ROOT}.logo-label`)}</label>
          {logoPreview(imageUrl)}
        </Upload>
      </Form.Item>

      {/* Organization Email */}
      <Form.Item
        name={EMAIL_FIELD_NAME}
        extra={t(`${TRANSLATION_ROOT}.email-sub-label`)}
        rules={[{ required: true, type: 'email' }]}
      >
        <InputBox label={t(`${TRANSLATION_ROOT}.email-label`)} required />
      </Form.Item>

      {/* Continue */}
      <ContinueButton
        label={t(TRANSLATION_BUTTON)}
        htmlType="submit"
        onClick={closeBannerIfExists}
        validateDirty={[EMAIL_FIELD_NAME, ORGANIZATION_NAME_FIELD_NAME, IMAGE_DETAILS_FIELD_NAME]}
      />
      <Button
        disabled={!formIsEmpty}
        onClick={() => navigateToNextStep(STEPS.IDP_CHOICE)}
        size="large"
        style={{
          visibility: sessionStorage.getItem(StorageKeys.DPA_STATUS) ? 'hidden' : 'visible',
          border: 'none',
          boxShadow: 'none',
        }}
        label={t('common.skip')}
        block
        data-testid="skip-button"
      />
    </Form>
  );
};

const OrganizationVerify = ({
  data,
  goBack,
  onVerify,
}: { data?: FormValues; goBack: () => void; onVerify: () => void }) => {
  const { t } = useTranslation();
  const TRANSLATION_ROOT = 'setup.org-info-confirm';

  console.log('[OrganizationVerify] data:', data);
  return (
    <div>
      <p>{t(`${TRANSLATION_ROOT}.content.label`)}</p>
      <p>{t(`${TRANSLATION_ROOT}.content.name-logo-label`)}</p>
      <h2>{data?.[ORGANIZATION_NAME_FIELD_NAME]}</h2>
      {/* img height can be removed after proper error handling for image upload will be implemented */}
      <img src={data?.[IMAGE_DETAILS_FIELD_NAME].url} alt="" style={{ height: '150px' }} />
      <p>{t(`${TRANSLATION_ROOT}.content.domain-name-label`)}</p>
      <h3>{data?.[EMAIL_FIELD_NAME]?.split('@')?.pop()}</h3>
      <Form>
        <ContinueButton secondaryButton={{ label: t('common.back-button'), onClick: goBack }} onClick={onVerify}>
          {t('common.continue-button')}
        </ContinueButton>
      </Form>
    </div>
  );
};

export default OrganizationRoute;
