import { _getEnv } from '../api/utils';

export const setToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) as string;
};

// Used to turn the path into a key for translations and graphics
//
// TODO: Fix this monstrosity
export const getPathKey = (route: string) => {
  if (route.length === 0) {
    return '';
  }

  let updatedRoute = route;

  if (updatedRoute.endsWith('/')) {
    updatedRoute = updatedRoute.slice(0, -1);
  }

  return updatedRoute.slice(1).replaceAll('/', '.');
};

export const getSPMetadataURL = () => {
  const { currentEnv } = _getEnv();
  const tenantId = new URLSearchParams(window.location.search).get('tenantId') || 'no-tenant-id';
  return `https://metadata.app.${currentEnv}.imprivata.cloud/${tenantId}/saml_metadata/sp_saml_metadata.xml`;
};

export const getPortalUrl = () => {
  const { currentEnv, tld } = _getEnv();
  return currentEnv === 'prod' ? 'https://access.imprivata.com' : `https://access.${currentEnv}.imprivata.${tld}`;
};

export const getIDPUrl = (tenantId: string, workflowId: string) => {
  const { currentEnv, tld } = _getEnv();
  const idpHost =
    currentEnv === 'prod' ? `${tenantId}.sys.imprivata.com` : `${tenantId}.sys.${currentEnv}.imprivata.${tld}`;
  const idpUrl = `https://${idpHost}/idp-web/idp/authenticate`;
  const targetUrl = getPortalUrl();
  const idpQueryParams = `?workflowId=${workflowId}&target=${targetUrl}`;

  return `${idpUrl}${idpQueryParams}`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.debug('Copied to clipboard');
    })
    .catch((e) => {
      console.error(`Copy to clipboard FAILED!: ${text}. E: `, e);
    });
};
