import type React from 'react';
import './PageLayout.less';
import { Layout } from 'antd';
import ImprivataLogo from '../../assets/Imprivata_Logo.svg?react';

export type PageHeaderProps = {
  title: string;
  className?: string;
  rightSlot?: React.ReactNode;
  logoContainerStyles?: React.CSSProperties;
};

const PageHeader = ({ title, rightSlot, className }: PageHeaderProps) => {
  const { Header } = Layout;

  return (
    <Header className={'header'}>
      <div className={`logo-container ${className || ''}`}>
        <div className={'header-left-content'}>
          <span className={'logo'}>
            <ImprivataLogo fill={'#2C3F57'} />
          </span>
          <span className={'logo-text'}>{title}</span>
        </div>
        <div className={'header-right-content'}>{rightSlot}</div>
      </div>
    </Header>
  );
};
export default PageHeader;
