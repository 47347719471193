import PageLayoutView from '../../../components/layout/PageLayoutView';
import { ErrorView } from '../../../errorHandler/ErrorView';

const PortalErrorView = () => {
  return (
    <PageLayoutView title={'Imprivata Access Management'}>
      <ErrorView />
    </PageLayoutView>
  );
};

export { PortalErrorView };
