import { useMutation } from '@tanstack/react-query';
import type React from 'react';
import { useSearchParams } from 'react-router-dom';
import { SetupState, StorageKeys } from '../../api/constants';
import * as api from '../../api/setupServices';
import { CheckDpaConsentResponseActionType } from '../../api/types';
import { ErrorCode } from '../../errorHandler/constants';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { type ApiError, type AppError, FatalError } from '../../errorHandler/errors';
import { SPANS_SETUP, tracer } from '../../utils/tracer';

type UseStartTenantSetupProps = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDpaNoActionNeeded: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStartTenantSetup = ({ setLoading, setDpaNoActionNeeded }: UseStartTenantSetupProps) => {
  const [searchParams] = useSearchParams();
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async () => {
      const bearerToken = searchParams.get('bt');
      await api.startTenantSetup(bearerToken);
    },
    onSuccess: async () => {
      console.log('Tenant setup started');
      tracer.endSpan(SPANS_SETUP.start_setup);
      sessionStorage.setItem(StorageKeys.TENANT_SETUP_STATE, SetupState.STARTED);
      tracer.startSpan(SPANS_SETUP.dpa_check);
      setLoading(true);
      try {
        const res = await api.dpaCheck();
        console.debug('DPA check end');
        tracer.endSpan(SPANS_SETUP.dpa_check);
        setDpaNoActionNeeded(res.actionType === CheckDpaConsentResponseActionType.NONE);
      } catch (e) {
        console.debug('DPA check failed', e);
        tracer.endSpan(SPANS_SETUP.dpa_check, { error: e });
        emitError(new FatalError(e as ApiError));
      } finally {
        setLoading(false);
      }
    },
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onError: (e: any) => {
      console.log('Tenant setup failed', e);
      tracer.endSpan(SPANS_SETUP.start_setup, { error: e });
      if (e.code === ErrorCode.INVALID_TENANT_STATE && e.data?.TENANT_STATE === 'ENABLED') {
        const appError = e as AppError;
        appError.code = ErrorCode.LINK_DEAD;
        emitError(appError);
      } else {
        const apiError = e as ApiError;
        emitError(new FatalError(apiError));
      }
    },
  });
};

export default useStartTenantSetup;
