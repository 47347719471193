import { Layout } from 'antd';
import type { PropsWithChildren, ReactNode } from 'react';
import PageHeader from './PageHeader';
import './PageLayout.less';

export type PageLayoutViewProps = {
  title: string;
  contentClassName?: string;
  footer?: ReactNode;
  displayHeader?: boolean;
};

const PageLayoutView = ({
  children,
  title,
  footer,
  displayHeader = true,
  contentClassName,
}: PropsWithChildren<PageLayoutViewProps>) => {
  const { Footer, Content } = Layout;

  return (
    <Layout>
      {displayHeader && <PageHeader title={title} />}
      <Content className={contentClassName}>{children}</Content>
      {footer && <Footer className={'footer'}>{footer}</Footer>}
    </Layout>
  );
};

export default PageLayoutView;
