import { Button } from '@imprivata-cloud/components';
import PageHeader from '../../../components/layout/PageHeader';
import SettingsMenu from './SettingsMenu';
import './PortalLanding.less';

type LandingPageHeaderProps = {
  userLogout: () => void;
};

const LandingPageHeader = ({ userLogout }: LandingPageHeaderProps) => {
  return (
    <PageHeader
      title={'Imprivata Access Management'}
      className={'page-header'}
      rightSlot={
        <div className={'landing-header-content'}>
          <SettingsMenu />
          <Button type={'text'} onClick={userLogout}>
            Logout
          </Button>
        </div>
      }
    />
  );
};

export default LandingPageHeader;
